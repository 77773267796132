import Cookie3SDK from "../../core";
import type { IPlugin } from "../../types";

export default class WalletTrackerPlugin implements IPlugin {
  protected coreInstance: Cookie3SDK | null = null;

  initialize(coreInstance: Cookie3SDK): void {
    this.coreInstance = coreInstance;
  }

  protected register(args: any): void {
    if (!this.coreInstance) {
      throw new Error("Core instance is not initialized.");
    }

    this.coreInstance.pushCustomDimension({
      id: 1,
      value: args.walletAddress,
    });
    // this.coreInstance.trackPageView();
  }

  public trackWallet(walletAddress: string): void {
    this.register({ walletAddress });
  }

  public close(): void {
    // Do nothing
  }
}
