import { TRACK_TYPES } from "./constants";

import type {
  IPlugin,
  CustomDimension,
  TrackEventParams,
  TrackLinkParams,
  TrackPageViewParams,
  TrackParams,
  UserOptions,
  Instance,
} from "./types";
import matomo from "./matomo.script";
import { generateUUID } from "./utils";
import WalletAutoDetectPlugin from "./plugins/WalletAutoDetectPlugin";
import ExtensionsPlugin from "./plugins/ExtensionsPlugin";

class Cookie3SDK implements Instance {
  private customTitle: string | undefined;
  private plugins: IPlugin[] = [];

  private customHref: string | undefined;

  constructor(userOptions: UserOptions) {
    if (!userOptions.siteId) {
      throw new Error("SiteId is required.");
    }

    this.initialize(userOptions);
  }

  private async initialize({
    siteId,
    customHref,
    customTitle,
    disabled,
    heartBeat,
    linkTracking = false,
    autoDetectWalletAddresses = true,
    autoDetectWalletExtensions = true,
    disableCookies = false,
    staging,
  }: UserOptions) {
    if (typeof window === "undefined") {
      return;
    }

    this.customTitle = customTitle;
    this.customHref = customHref;

    window._paq = window._paq || [];

    if (window._paq.length !== 0) {
      return;
    }

    if (disabled) {
      return;
    }

    this.pushInstruction(
      "setTrackerUrl",
      staging
        ? "https://a.staging.cookie3.co/lake"
        : "https://webanalytics.cookie3.co/lake"
    );

    this.pushInstruction("setSiteId", siteId);

    let userId = null;
    if (disableCookies) {
      try {
        const _userId = localStorage?.getItem("_c_id");

        if (_userId) {
          userId = _userId;
        }
        if (!userId) {
          userId = generateUUID();

          localStorage?.setItem("_c_id", userId);
        }
      } catch (e) {}
    }

    if (userId) this.pushUserId(userId);

    if (autoDetectWalletAddresses || autoDetectWalletAddresses) {
      const walletAutoDetectPlugin = autoDetectWalletAddresses
        ? new WalletAutoDetectPlugin()
        : null;
      const extensionsPlugin = autoDetectWalletExtensions
        ? new ExtensionsPlugin()
        : null;

      const plugins = [walletAutoDetectPlugin, extensionsPlugin].filter(
        Boolean
      ) as IPlugin[];

      this.initializePlugins(plugins);
    }

    // Object.entries(configurations).forEach(([name, instructions]) => {
    //   if (instructions instanceof Array) {
    //     this.pushInstruction(name, ...instructions);
    //   } else {
    //     this.pushInstruction(name, instructions);
    //   }
    // });

    if (!heartBeat || heartBeat?.active) {
      this.enableHeartBeatTimer((heartBeat && heartBeat.seconds) ?? 15);
    }

    if (linkTracking) this.enableLinkTracking(linkTracking);

    matomo();
    this.initializePlugins();
  }

  enableHeartBeatTimer(seconds: number) {
    this.pushInstruction("enableHeartBeatTimer", seconds);
  }

  enableLinkTracking(active: boolean) {
    this.pushInstruction("enableLinkTracking", active);
  }

  initializePlugins(plugins: IPlugin[] = this.plugins) {
    plugins.forEach((plugin) => {
      plugin.initialize(this);
    });

    this.plugins = plugins;
  }

  // close all plugins
  close(): void {
    this.plugins.forEach((plugin) => {
      plugin.close();
    });
  }

  trackEvent({
    category,
    action,
    name,
    value,
    ...otherParams
  }: TrackEventParams) {
    if (category && action) {
      this.track({
        data: [TRACK_TYPES.TRACK_EVENT, category, action, name, value],
        ...otherParams,
      });
    } else {
      throw new Error(`Error: category and action are required.`);
    }
  }

  // Tracks outgoing links to other sites and downloads
  // https://developer.matomo.org/guides/tracking-javascript-guide#enabling-download-outlink-tracking
  trackLink({ href, linkType = "link" }: TrackLinkParams) {
    this.pushInstruction(TRACK_TYPES.TRACK_LINK, href, linkType);
  }

  // Tracks page views
  // https://developer.matomo.org/guides/spa-tracking#tracking-a-new-page-view
  async trackPageView(params?: TrackPageViewParams) {
    const promises = this.plugins.map((plugin) => plugin.track?.());
    await Promise.all(promises);

    this.track({ data: [TRACK_TYPES.TRACK_VIEW], ...params });
  }

  pushCustomDimension(customDimension: CustomDimension) {
    return this.pushInstruction(
      "setCustomDimension",
      customDimension.id,
      customDimension.value
    );
  }

  pushCustomDimensions(customDimensions: CustomDimension[]) {
    if (!this.arrayHasValues(customDimensions)) return this;

    customDimensions.map((customDimension) => {
      this.pushCustomDimension(customDimension);
    });

    return this;
  }

  pushUserId(userId: string) {
    this.pushInstruction("setUserId", userId);
  }

  // Sends the tracked page/view/search
  track({
    data = [],
    documentTitle = this.customTitle ?? window.document.title,
    href = this.customHref ?? window.location.href,
    customDimensions = [],
  }: TrackParams) {
    if (data.length) {
      if (typeof href === "object")
        // Probably of type location
        href = href.toString();
      else if (href?.indexOf("http") !== 0) {
        href = window.location.origin + href + window.location.search;
      }

      if (customDimensions) {
        this.pushCustomDimensions(customDimensions);

        // Automatically remove any custom dimensions that were set.
        const dimensionsToReset = customDimensions
          .filter((cd) => cd.keepAfterOperation !== true)
          .map((cd) => ({
            ...cd,
            value: undefined,
          }));
        this.pushCustomDimensions(dimensionsToReset);
      }

      this.pushInstruction("setCustomUrl", href);
      this.pushInstruction("setDocumentTitle", documentTitle);
      this.pushInstruction(...(data as [string, ...any[]]));
    }
  }

  pushInstruction(name: string, ...args: any[]) {
    window?._paq.push([name, ...args]);
    return this;
  }

  arrayHasValues(customDimensions: CustomDimension[]) {
    return !!(
      customDimensions &&
      Array.isArray(customDimensions) &&
      customDimensions.length
    );
  }
}

export default Cookie3SDK;
