import Cookie3SDK from "../../core";
import type { IPlugin } from "../../types";

export default class WalletDetectionPlugin implements IPlugin {
  protected coreInstance: Cookie3SDK | null = null;
  private isInitialized = false;
  private interval: ReturnType<typeof setInterval> | null = null;
  private identifiedWallet = null;

  initialize(coreInstance: Cookie3SDK): void {
    if (typeof window === "undefined") {
      return;
    }
    this.coreInstance = coreInstance;
    this.setupListeners();
    this.isInitialized = true;
  }

  protected register(event: {
    action: string;
    category: string;
    name: string;
  }): void {
    if (!this.coreInstance) {
      throw new Error("Core instance is not initialized.");
    }

    this.coreInstance.trackEvent(event);
  }

  private setupListeners(): void {
    if (this.isInitialized) {
      return;
    }
    const ethereumExt = (window as any)?.ethereum;

    // addEventListener("load", this.onWindowLoad.bind(this));

    if (!ethereumExt) {
      return;
    }
    ethereumExt?.on("accountsChanged", this.onAccountsChanged.bind(this));
    ethereumExt?.on("disconnect", this.onDisconnect.bind(this));
    ethereumExt?.on("connect", this.onConnect.bind(this));
    ethereumExt?.on("chainChanged", this.onChainChanged.bind(this));
  }

  private async onWindowLoad(): Promise<void> {
    const promise = new Promise((resolve) => {
      this.interval = setTimeout(() => {
        let connectedAddress;
        const windowWrapper = window as any;
        const selectedAddress =
          windowWrapper?.ethereum?.selectedAddress ||
          windowWrapper?.web3?.currentProvider?.selectedAddress;

        if (selectedAddress && connectedAddress !== selectedAddress) {
          connectedAddress = selectedAddress;
        }

        if (!connectedAddress) return;
        this._trackWallet(connectedAddress);
      }, 2500);
    });

    await promise;
  }

  private _trackWallet(walletAddress: string): void {
    this.coreInstance?.pushCustomDimension({
      id: 1,
      value: walletAddress,
    });
  }

  async track(): Promise<void> {
    const promise = new Promise((resolve) => {
      this.interval = setTimeout(() => {
        let connectedAddress;
        const windowWrapper = window as any;
        const selectedAddress =
          windowWrapper?.ethereum?.selectedAddress ||
          windowWrapper?.web3?.currentProvider?.selectedAddress;

        if (selectedAddress && connectedAddress !== selectedAddress) {
          connectedAddress = selectedAddress;
        }

        if (!connectedAddress) {
          resolve(null);
          return;
        }
        this._trackWallet(connectedAddress);

        resolve(null);
      }, 2500);
    });

    await promise;

    return;
  }

  private onChainChanged(chainId: string): void {
    this.register({
      category: "Wallet",
      action: "ethChainChanged",
      name: Number(chainId).toString(),
    });

    this.coreInstance?.pushCustomDimension({
      id: 3,
      value: Number(chainId).toString(),
    });
  }

  private onConnect(): void {
    this.register({
      category: "Wallet",
      action: "ethConnect",
      name: "",
    });
  }

  private onDisconnect(): void {
    this.coreInstance?.trackEvent({
      category: "Wallet",
      action: "ethDisconnect",
      name: "",
    });
  }

  private onAccountsChanged(accounts: string[]): void {
    let newAccount = accounts.length > 0 ? accounts[0] : "";

    this.coreInstance?.pushCustomDimension({
      id: 1,
      value: newAccount,
    });

    this.register({
      category: "Wallet",
      action: "ethAccountsChanged",
      name: newAccount,
    });
  }

  private removeListeners(): void {
    if (!this.isInitialized) {
      return;
    }

    const ethereumExt = (window as any)?.ethereum;

    ethereumExt?.removeListener(
      "accountsChanged",
      this.onAccountsChanged.bind(this)
    );
    ethereumExt?.removeListener("disconnect", this.onDisconnect.bind(this));
    ethereumExt?.removeListener("connect", this.onConnect.bind(this));
    ethereumExt?.removeListener("chainChanged", this.onChainChanged.bind(this));
    window?.removeEventListener("load", this.onWindowLoad.bind(this));
  }

  public close(): void {
    this.removeListeners();
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
