import Cookie3SDK from "../../core";
import type { IPlugin } from "../../types";

function getEventName(target: HTMLElement): string {
  if (target.nodeName === "BUTTON") {
    return target.innerText;
  }

  if (target.nodeName === "A") {
    return target.getAttribute("href") || "";
  }

  if (target.nodeName === "IMG") {
    return target.getAttribute("src") || "";
  }

  return target.nodeName;
}

export default class InteractionsPlugin implements IPlugin {
  protected coreInstance: Cookie3SDK | null = null;
  private isInitialized = false;

  protected register(event: {
    action: string;
    category: string;
    name: string;
  }): void {
    if (!this.coreInstance) {
      throw new Error("Core instance is not initialized.");
    }

    this.coreInstance.trackEvent(event);
  }

  initialize(coreInstance: Cookie3SDK): void {
    if (typeof window === "undefined") {
      return;
    }
    this.coreInstance = coreInstance;
    this.setupListeners();
    this.isInitialized = true;
  }

  private setupListeners(): void {
    if (this.isInitialized) {
      return;
    }
    document.addEventListener("mousedown", this.handleClicks.bind(this));
  }

  private removeListeners(): void {
    if (!this.isInitialized) {
      return;
    }
    document.removeEventListener("mousedown", this.handleClicks.bind(this));
  }

  private handleClicks(event: MouseEvent): void {
    if (event.target instanceof HTMLElement) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      event.preventDefault();

      const target = event.target;

      this.register({
        action: "click",
        category: "heatmap events",
        name: getEventName(target),
      });

      const customEvent = new CustomEvent("cookie3:interaction", {
        bubbles: false,
        detail: {
          text: () => `Interaction: click on ${getEventName(target)}`,
        },
      });

      document.dispatchEvent(customEvent);
    }
  }

  public close(): void {
    this.removeListeners();
  }
}
