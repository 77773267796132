import Cookie3SDK from "../../core";
import type { IPlugin } from "../../types";

import WALLET_CHECKS from "./constants/extensions";

export default class ExtensionsPlugin implements IPlugin {
  protected coreInstance: Cookie3SDK | null = null;
  private areExtensionsExtracted = false;

  initialize(coreInstance: Cookie3SDK): void {
    if (typeof window === "undefined") {
      return;
    }
    this.coreInstance = coreInstance;
    window?.addEventListener("load", () => {
      this.trackExtensions();
    });
  }

  protected register(args: any): void {
    if (!this.coreInstance) {
      throw new Error("Core instance is not initialized.");
    }

    this.coreInstance.pushCustomDimension({
      id: 2,
      value: args.extensions,
    });

    // this.coreInstance.trackPageView();
  }

  public trackExtensions(): void {
    if (this.areExtensionsExtracted) {
      return;
    }

    const extensions = this._trackExtensions();
    this.register({ extensions });

    this.areExtensionsExtracted = true;
  }

  track() {
    this.trackExtensions();

    return Promise.resolve();
  }

  // This method should be called only once
  private _trackExtensions(): string {
    const extensionsResult: string[] = [];

    WALLET_CHECKS().forEach((extension, index) => {
      if (extension.condition) {
        extensionsResult.push(extension.name.toLowerCase());
      }
    });

    const extensionsResultString = extensionsResult.join("|");

    return extensionsResultString;
  }

  public close(): void {
    window?.removeEventListener("load", () => {
      this.trackExtensions();
    });
  }
}
