import Cookie3SDK from "./core";
import { InteractionsPlugin, WalletManualPlugin } from "./plugins";
import { UserOptions } from "./types";

interface Cookie3Analytics {
  trackEvent: Cookie3SDK["trackEvent"];
  trackPageView: Cookie3SDK["trackPageView"];
  trackOutgoingLink: Cookie3SDK["trackLink"];
  close: Cookie3SDK["close"];
}

/**
 * @param config
 * @param config.siteId - The ID of the site we want to track
 * @param config.disabled - Whether to disable tracking
 * @param config.autoDetectWalletAddresses - Whether to automatically detect wallet addresses (default: true)
 * @param config.autoDetectWalletExtensions - Whether to automatically detect wallet extensions (default: true)
 * @param config.heartBeat - Whether to enable heartbeats (default: false)
 * @param config.linkTracking - Whether to enable link tracking (default: false)
 * @param config.disableCookies - Whether to disable cookies (default: false)
 *
 * @returns
 * @example
 * ```ts
 * import { cookie3Analytics } from "@cookie3/sdk";
 *
 * const analytics = cookie3Analytics({
 *  siteId: 1,
 *  disableCookies: true,
 * });
 *
 * analytics.trackPageView();
 *
 * ```
 *
 * @example
 * ```ts
 * analytics.trackEvent({
 *  category: "test",
 *  action: "test",
 *  name: "test",
 *  value: 1,
 * });
 *
 * ```
 *
 * @example
 * ```ts
 * analytics.trackOutgoingLink({
 * href: "https://cookie3.co",
 * linkType: "link",
 * });
 *  ```
 */

function cookie3Analytics(config: UserOptions): Cookie3Analytics {
  const coreInstance = new Cookie3SDK(config);

  const trackEvent = coreInstance.trackEvent.bind(coreInstance);
  const trackPageView = coreInstance.trackPageView.bind(coreInstance);

  const trackOutgoingLink = coreInstance.trackLink.bind(coreInstance);
  const close = coreInstance.close.bind(coreInstance);

  coreInstance.initializePlugins(config.plugins);

  return {
    trackEvent,
    trackPageView,
    trackOutgoingLink,
    close,
  };
}

export type { UserOptions } from "./types";
export type { Cookie3Analytics };

export { cookie3Analytics, WalletManualPlugin, InteractionsPlugin };
