const getWalletChecks = () => {
  const ethereumExt = (window as any).ethereum;
  const tronWebExt = (window as any).tronWeb;

  const phantomSolana = (window as any).phantom?.solana;
  const icPlug = (window as any).ic?.plug;

  const WALLET_CHECKS = [
    {
      name: "Metamask",
      condition: ethereumExt?._metamask != null && ethereumExt?.isMetaMask,
    },
    {
      name: "CoinbaseWallet",
      condition: ethereumExt?.isCoinbaseWallet === true,
    },
    { name: "Keplr", condition: (window as any).keplr?.mode === "extension" },
    { name: "Phantom", condition: phantomSolana?.isPhantom === true },
    {
      name: "CryptoComWallet",
      condition: (window as any).deficonnectProvider != null,
    },
    { name: "TrustWallet", condition: ethereumExt?.isTrust === true },
    { name: "Coin98Wallet", condition: ethereumExt?.isCoin98 === true },
    { name: "TallyHo", condition: (window as any).tally?.isTally === true },
    { name: "Liquality", condition: (window as any).liquality != null },
    { name: "PlugWallet", condition: icPlug != null },
    {
      name: "LilicoWallet",
      condition: (window as any).fcl_extensions?.some(
        (x: any) => x.uid === "Lilico"
      ),
    },
    { name: "RabbyWallet", condition: ethereumExt?.isRabby === true },
    { name: "MaiarWallet", condition: (window as any).elrondWallet != null },
    { name: "XDEFIWallet", condition: ethereumExt?.isXDEFI === true },
    { name: "ForboleX", condition: (window as any).forboleX != null },
    { name: "TronLink", condition: (window as any).tron?.isTronLink === true },
    {
      name: "PocketUniverse",
      condition: ethereumExt?.isPocketUniverse === true,
    },
    { name: "HiroWallet", condition: (window as any).StacksProvider != null },
    { name: "NamiWallet", condition: (window as any).cardano?.nami != null },
    {
      name: "SenderWallet",
      condition: (window as any).near?.isSender === true,
    },
    { name: "SuiWallet", condition: (window as any).suiWallet != null },
    { name: "PontemAptosWallet", condition: (window as any).pontem != null },
    { name: "MartianWallet", condition: (window as any).martian != null },
    { name: "PetraAptosWallet", condition: (window as any).petra != null },
    { name: "EthosSuiWallet", condition: (window as any).ethosWallet != null },
    {
      name: "ArgentXWallet",
      condition: (window as any).starknet_argentX != null,
    },
    { name: "AleWallet", condition: (window as any).aleereum?.isAle === true },
    {
      name: "SuietWallet",
      condition: (window as any).__suiet__?.name === "Suiet",
    },
    {
      name: "BraavosWallet",
      condition: (window as any).starknet_braavos != null,
    },
    {
      name: "SubWallet",
      condition: (window as any).SubWallet?.isSubWallet === true,
    },
    {
      name: "ArConnect",
      condition: (window as any).arweaveWallet?.walletName === "ArConnect",
    },
    { name: "ZkIDWallet", condition: (window as any).zkid != null },
    { name: "RiseWallet", condition: (window as any).rise?.isRise === true },
    {
      name: "FewchaWallet",
      condition: (window as any).fewcha?.isFewcha === true,
    },
    {
      name: "SolflareWallet",
      condition: (window as any).solflare?.isSolflare === true,
    },
    { name: "YoroiWallet", condition: (window as any).cardano?.yoroi != null },
    {
      name: "MathWallet",
      condition:
        (window as any).injectedWeb3?.mathwallet != null ||
        ethereumExt?.isMathWallet === true ||
        tronWebExt?.isMathWallet === true ||
        phantomSolana?.isMathWallet === true,
    },
    {
      name: "CosmostationWallet",
      condition: (window as any).cosmostation != null,
    },
  ];

  return WALLET_CHECKS;
};

export default getWalletChecks;
